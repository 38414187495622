import { h } from 'preact';
import style from './style.css';
import { Lang } from '../../app';

export const Header = ({ handleChangeLang }) => <Lang.Consumer>{translations => (
    <header class={style.header}>
        <h1>GesBNI</h1>
        <select onchange={handleChangeLang}>
            <option value="es">{translations.lang.spanish}</option>
            <option value="en">{translations.lang.english}</option>
        </select>
        <h2 class={style.legend}>{translations.main.legend}</h2>
    </header>
)}</Lang.Consumer>;
