import { h } from 'preact';

export const Player = ({ url }) => (
    <div class="tile video">
        <iframe
            width="390"
            height="219"
            src={url}
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        />
    </div>
);