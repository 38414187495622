import { h } from 'preact';
import style from './style.css';
import { mapToComponent } from '../../../utils/map_to_component';
import { Lang } from '../../app';

const getHeader = id => id
    ? <Lang.Consumer>{translations => (
        <header>
            <h3 class={style.title}>{translations.section[id]}</h3>
        </header>
    )}</Lang.Consumer>
    : null;

const getContent = (items, sectionId, props) => sectionId
    ? <div class={style.wrapper}>{items.map(mapToComponent(props))}</div>
    : items.map(mapToComponent(props));

export const Section = ({ items, sectionId, ...props }) => (
    <section class={sectionId ? style.grid : style.main}>
        {getHeader(sectionId)}
        {getContent(items, sectionId, props)}
    </section>
);
