import { h } from 'preact';
import style from './style.css';
import { Lang } from '../app';

export const Ribbon = ({ ribbon }) => <Lang.Consumer>{translations => (
    <span
        class={style.ribbon}
        style={{
            "--bg-color": ribbon.bgColor,
            "--color": ribbon.color,
        }}
    >
        {translations.ribbon[ribbon.literal]}
    </span>
)}</Lang.Consumer>;