import { h } from 'preact';
import { Ribbon } from '../ribbon';

export const Link = ({ id, href, asset, ribbon }) => (
    <a
        id={id}
        class="tile"
        href={href}
        style={{ "--image": `url(${asset}` }}
    >
        {ribbon && <Ribbon ribbon={ribbon}/>}
    </a>
);
