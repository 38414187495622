import { h, Component, createContext } from "preact";
import linkState from "linkstate";

import { Header } from "./_sections/header";
import { EventEmitter } from "events";
import { Section } from "./_sections/section";

export const events = {
    SET_CURRENT_VIDEO: "SET_CURRENT_VIDEO"
};

export const Lang = createContext("es");
export default class App extends Component {
    bus = new EventEmitter()
    state = {
        lang: "es",
        currentVideo: null,
        items: null,
        translations: null,
    }

    componentDidMount = () => {
        import("../assets/items.json").then(linkState(this, "items", "default"));
        import("../assets/lang.json").then(linkState(this, "translations","default"));
        this.bus.on(events.SET_CURRENT_VIDEO, linkState(this, "currentVideo"));
    }

    handleChangeLang = event => {
        this.setState({
            lang: event.target.value,
            currentVideo: null,
        });
    };

    render (_, { lang, currentVideo, items, translations }) {
        if (!translations || !items) return null;
        const common = {
            currentVideo,
            bus: this.bus,
        };

        return (
            <div id="app">
                <Lang.Provider value={translations[lang]}>
                    <Header handleChangeLang={this.handleChangeLang}/>
                    <Section {...common} items={items.main}/>
                    <img
                        class="top"
                        src="/assets/images/chevron_down.png"
                        title={translations[lang].main.back_to_top}
                        alt={translations[lang].main.back_to_top}
                        onClick={() => window.scrollTo(0, 0)}
                    />
                </Lang.Provider>
            </div>
        );
    }
}
