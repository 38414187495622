import { h } from "preact";
import { Ribbon } from '../ribbon';
import { events } from '../app';

export const Button = ({ id, asset, ribbon, bus }) => (
    <button
        id={id}
        type="button"
        class="tile"
        style={{ "--image": `url(${asset})` }}
        onClick={() => bus.emit(events.SET_CURRENT_VIDEO, id)}
    >
        {ribbon && <Ribbon ribbon={ribbon}/>}
    </button>
);